<template>
  <td
    class="text-body-1"
    :style="{
      textAlign: header.align ? header.align : 'center',
      whiteSpace: noWrap ? 'nowrap' : '',
      paddingLeft: additionalPadding ? additionalPadding : undefined,
      paddingRight: additionalPadding ? additionalPadding : undefined,
      color: color,
    }"
  >
    <slot>{{ defaultValue }}</slot>
  </td>
</template>

<script>
export default {
  props: {
    header: {
      default: undefined,
    },
    item: {
      default: undefined,
    },
    value: {},
    noWrap: {
      type: Boolean,
      default: false,
    },
    additionalPadding: {
      type: String,
      default: "",
    },
    color: {
      default: "black"
    }
  },
  computed: {
    defaultValue() {
      if (this.value) {
        return this.value;
      } else if (this.item == undefined || this.header == undefined) {
        return "";
      } else {
        return this.item[this.header.value];
      }
    },
  },
};
</script>
